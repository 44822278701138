<template>
    <div class="rule">
        <el-tabs v-model="activeName" @tab-click="handleClick" class="el-nav">
            <el-tab-pane label="我的余额" name="balance"></el-tab-pane>

            <div class="title">
                <div class="left">当前金币</div>
                <div class="right">
                    <img src="~assets/images/rule/waring.png" alt=""/>
                    <span>充值余额不可提现，仅限用于本平台发放金币</span>
                </div>
            </div>
            <div class="topar">
                <div class="num">{{ account.gold }}</div>
                <el-button type="primary" class="search-btn par" @click="toPar">去充值</el-button>
            </div>
        </el-tabs>
        <div class="list">
            <div class="info_list">
                <img src="~assets/images/data/purse.png" alt=""/>
                <span>余额明细</span>
            </div>
            <div class="line"></div>
            <el-table
                    v-loading="loading"
                    class="table"
                    ref="multipleTable"
                    :data="data.data"
                    tooltip-effect="dark"
                    :header-cell-style="{ background: '#F6F8F9', color: '#1C1F21', fontWeight: '400' }"
                    border
                    v-if="data.meta ? data.meta.total : 0"
            >
                <el-table-column label="用户" width="180px">
                    <template slot-scope="scope">
                        <div class="el-name">
                            <p>昵称： {{ scope.row.nickname ? scope.row.nickname : '--' }}</p>
                            <p>手机： {{ scope.row.mobile ? scope.row.mobile  : '--'}}</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="金额" width="100px" prop="pay">
                    <template slot-scope="scope">
                        <div class="el-name">￥{{ scope.row.amount }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="100px" prop="state">
                    <template slot-scope="scope">
                        <div class="el-name">{{ scope.row.mode === 'increase' ? '转入' : '支出' }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="结余" width="100px" prop="jieyu">
                    <template slot-scope="scope">
                        <div class="el-name">{{ scope.row.total_amount }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="时间" prop="time" width="170">
                    <template slot-scope="scope">
                        <div class="el-name">{{ scope.row.created_at }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="备注" prop="beizhu">
                    <template slot-scope="scope">
                        <div class="el-name">{{ scope.row.remark_text }}</div>
                    </template>
                </el-table-column>
            </el-table>
            <empty-prompt style="margin-top: 120px; margin-bottom: 155px;" v-else></empty-prompt>
            <!-- v-if="listData.length !== 0 && total > 10" -->
            <el-pagination
                    v-if="data.meta && data.meta.last_page > 1"
                    class="page"
                    background
                    @current-change="handleCurrentChange"
                    :current-page="page"
                    :page-sizes="[20, 50, 100]"
                    :page-size="pageSize"
                    layout="prev, pager, next,total, jumper"
                    :total="data.meta ? data.meta.total : 0"
            >
            </el-pagination>
        </div>
    </div>
</template>
<script>
    import EmptyPrompt from 'components/EmptyPrompt';
    export default {
        props: {},
        data() {
            return {
                loading: false,
                activeName: 'balance',
                page: 1,
                pageSize: 10,
                total: 0,
                account: {
                    gold: 0,
                },
                data: [],
            };
        },
        created() {
            this.getData()
        },
        mounted() {
            this.account.gold = this.$store?.getters?.userInfo?.managed_circle?.account?.gold;
            if (!this.account.gold) {
                this.$http.get(this.$api.userInfo, true).then(result => {
                    if (result.data.success) {
                        this.account.gold = result.data.data.managed_circle.account.gold;
                    }
                })
            }
        },
        methods: {
            getData() {
                this.$http.get(this.$api.circlesAccountLogs + `?page=${this.page}&pageSize=${this.pageSize}`, true).then(result => {
                    if (result.data.success) {
                        this.data = result.data.data;
                    }
                })
            },
            // tab切换
            handleClick() {
            },
            // 分页
            handleCurrentChange(val) {
                this.page = val;
                this.getData();
            },
            toPar() {
                this.$router.push({
                    path: 'Pay',
                });
            },
        },
        components: {
            EmptyPrompt,
        },
    };
</script>
<style lang="less" scoped>
    @import '~assets/less/balance/index.less';
</style>
